import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import Handsontable from "handsontable";
import moment from 'moment';

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/service/fineweb/branch/main/build/src/app/page.main/view.scss */
.handsontable-container {
  width: 100%;
  height: 100%;
  flex: auto;
  margin: 0 auto;
}

.container {
  display: flex;
  height: calc(100% - 85px);
  max-width: 1480px;
  padding: 0;
}

.navbar {
  border-bottom: 1px solid var(--wc-border);
  background: #fff;
}
.navbar .navbar-title {
  font-size: 24px;
}
.navbar .container {
  justify-content: initial;
  padding: 0;
  max-width: 1440px;
}
.navbar .navbar-brand img {
  height: 36px;
}
.navbar .navbar-toggler {
  color: var(--wc-blue);
  margin-right: 12px;
}
@media (min-width: 769px) {
  .navbar .menu-container {
    width: 100%;
  }
  .navbar .menu-container .wiz-top-menu {
    display: flex;
    justify-content: end;
    padding-top: 16px;
  }
  .navbar .menu-container .wiz-top-menu .wiz-menu-item {
    min-width: 64px;
    padding-left: 16px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 14px;
  }
  .navbar .menu-container .wiz-main-menu {
    display: flex;
    justify-content: end;
    padding: 14px 24px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item {
    min-width: 108px;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 16px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item .nav-link {
    font-weight: 1000;
    font-family: "main-eb";
  }
  .navbar .menu-container .wiz-main-menu .nav-item .active {
    color: var(--wc-blue);
  }
}
.navbar a {
  cursor: pointer;
}
@media (max-width: 768px) {
  .navbar .navbar-brand img {
    height: 32px;
  }
  .navbar .wiz-top-menu {
    padding-top: 0;
  }
}`],
})
export class PageMainComponent implements OnInit {
    @ViewChild('handsontable')
    public table: ElementRef;
    public handsontable: any = null;
    public loaded: boolean = false;
    public data: any = [];
    public orgdata: any = [];
    public isreadonly: boolean = false;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.auth.allow(true, '/login');
    }

    public async load() {
        let { code, data } = await wiz.call("items");
        if (code != 200) {
            return;
        }

        let isreadonly = data.readOnly;
        this.isreadonly = isreadonly;
        data = data.data
        this.loaded = true;
        await this.service.render();

        let colHeaders = [
            "ID",
            "Group",
            "검색어",
            "상품명",
            "제목"
        ];

        let columns = [
            { data: 'id', type: "text", readOnly: true },
            { data: 'group', type: "text" },
            {
                data: 'search', renderer: function (instance, td, row, col, prop, value) {
                    if (value) {
                        let url = 'https://search.shopping.naver.com/search/all?query=' + encodeURIComponent(value);
                        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
                        td.innerHTML = '<a href="' + url + '" target="_blank">' + value + '</a>';
                    } else {
                        td.innerHTML = '';
                    }
                }
            },
            {
                data: 'product', renderer: function (instance, td, row, col, prop, value) {
                    if (value) {
                        let url = 'https://search.shopping.naver.com/search/all?query=' + encodeURIComponent(value);
                        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
                        td.innerHTML = '<a href="' + url + '" target="_blank">' + value + '</a>';
                    } else {
                        td.innerHTML = '';
                    }
                }
            },
            { data: 'target', type: "text" }
        ];
        let day = moment().add(-8, 'days');
        let days = [];
        for (let i = 0; i < 9; i++) {
            days.push(day.format('YYYY-MM-DD'));
            colHeaders.push(day.format('MM/DD'));
            columns.push({ data: day.format('YYYY-MM-DD'), renderer: "html", readOnly: true });
            day = day.add(1, 'days');
        }

        colHeaders.push("");
        columns.push({ data: 'action', type: "dropdown", source: ['', '삭제', '변경'] });

        for (let i = 0; i < data.length; i++) {
            let item = data[i];

            for (let j = 0; j < days.length; j++) {
                let tday = days[j];
                try {
                    let total_rank = item.rank_log[tday].total_rank;
                    let list_rank = item.rank_log[tday].list_rank;
                    // let search_rank = item.rank_log[tday].search_rank;
                    if (total_rank == list_rank)
                        data[i][tday] = `<div style="line-height: 1; text-align: center; padding: 8px 0;">${total_rank}</div>`;
                    else
                        data[i][tday] = `<div style="line-height: 1; text-align: center; padding: 8px 0;">${total_rank} (${list_rank})</div>`;
                } catch (e) {
                    data[i][tday] = `<div style="line-height: 1; text-align: center; padding: 8px 0;">&nbsp;<br/>&nbsp;</div>`;
                }
            }
        }

        this.data = data;
        this.orgdata = JSON.parse(JSON.stringify(this.data));

        let ctx = this.table.nativeElement;
        this.handsontable = new Handsontable(ctx, {
            data: data,
            className: 'vcenter',
            height: '100%',
            colWidths: [60, 120, 140, 180, 310,
                60, 60, 60, 60, 60, 60, 60,
                60, 60, 60, 60, 60, 60, 60],
            colHeaders: colHeaders,
            columns: columns,
            dropdownMenu: true,
            hiddenColumns: {
                indicators: true
            },
            contextMenu: false,
            multiColumnSorting: true,
            filters: true,
            rowHeaders: true,
            manualRowMove: true,
            minSpareRows: isreadonly ? 0 : 5,
            readOnly: isreadonly,
            licenseKey: "non-commercial-and-evaluation"
        });

        await this.service.render(1000);

        this.handsontable.addHook('afterChange', (row) => {
            row = row[0];

            let rownum = row[0];
            let col = row[1];
            let changevalue = row[3];
            if (!this.orgdata[rownum]) {
                let newitem = {};
                newitem[col] = changevalue;
                this.data.push(newitem);
                this.orgdata.push(newitem);
                return;
            }

            if (this.data[rownum].action == '삭제' || (col == 'action' && changevalue == '삭제')) {
                for (let i = 0; i < this.handsontable.countCols(); i++) {
                    this.handsontable.setCellMeta(rownum, i, 'className', 'vcenter bg-red-lt');
                }
                this.handsontable.getInstance().render();
                return;
            }

            if (!this.data[rownum].id) {
                this.data[rownum].action = '신규';
                for (let i = 0; i < this.handsontable.countCols(); i++)
                    this.handsontable.setCellMeta(rownum, i, 'className', 'vcenter bg-blue-lt');
                this.handsontable.getInstance().render();
                return;
            }

            this.data[rownum].action = '';
            for (let i = 0; i < this.handsontable.countCols(); i++)
                this.handsontable.setCellMeta(rownum, i, 'className', 'vcenter');

            let matcher = 'group,search,product,target'.split(',');
            let ismatch = true;
            for (let i = 0; i < matcher.length; i++) {
                let col = matcher[i];
                if (this.data[rownum][col] != this.orgdata[rownum][col]) {
                    ismatch = false;
                    break;
                }
            }

            if (!ismatch) {
                this.data[rownum].action = '변경';
                for (let i = 0; i < this.handsontable.countCols(); i++) {
                    this.handsontable.setCellMeta(rownum, i, 'className', 'vcenter bg-blue-lt');
                }
            }

            this.handsontable.getInstance().render();
        });
    }

    public async update() {
        let updated = [];
        for (let i = 0; i < this.data.length; i++) {
            let item = this.data[i];
            if (item.action && item.action.length > 0) {
                updated.push({
                    id: item.id,
                    group: item.group,
                    search: item.search,
                    product: item.product,
                    target: item.target,
                    action: item.action
                });
            }
        }

        let { code, data } = await wiz.call("update", { data: JSON.stringify(updated) });

        if (code == 200) {
            this.loaded = false;
            await this.service.render();
            await this.load();
        } else {

        }

    }

}

export default PageMainComponent;