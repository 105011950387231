import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-nav',
template: templateSource || '',
    styles: [`

/* file: /opt/service/fineweb/branch/main/build/src/app/component.nav/view.scss */
.navbar {
  border-bottom: 1px solid var(--wc-border);
  background: #fff;
}
.navbar .navbar-title {
  font-size: 24px;
}
.navbar .container {
  justify-content: initial;
  padding: 0;
  max-width: 1440px;
}
.navbar .navbar-brand img {
  height: 36px;
}
.navbar .navbar-toggler {
  color: var(--wc-blue);
  margin-right: 12px;
}
@media (min-width: 769px) {
  .navbar .menu-container {
    width: 100%;
  }
  .navbar .menu-container .wiz-top-menu {
    display: flex;
    justify-content: end;
    padding-top: 16px;
  }
  .navbar .menu-container .wiz-top-menu .wiz-menu-item {
    min-width: 64px;
    padding-left: 16px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 14px;
  }
  .navbar .menu-container .wiz-main-menu {
    display: flex;
    justify-content: end;
    padding: 14px 24px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item {
    min-width: 108px;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 16px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item .nav-link {
    font-weight: 1000;
    font-family: "main-eb";
  }
  .navbar .menu-container .wiz-main-menu .nav-item .active {
    color: var(--wc-blue);
  }
}
.navbar a {
  cursor: pointer;
}
@media (max-width: 768px) {
  .navbar .navbar-brand img {
    height: 32px;
  }
  .navbar .wiz-top-menu {
    padding-top: 0;
  }
}`],
})
export class ComponentNavComponent implements OnInit {
    public isMenuCollapsed: boolean = true;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default ComponentNavComponent;