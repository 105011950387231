import '@angular/compiler';
import { ComponentAsideComponent } from './component.aside/component.aside.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        ComponentAsideComponent,
        ComponentNavComponent,
        LayoutAsideComponent,
        LayoutEmptyComponent,
        LayoutTopnavComponent,
        PageLoginComponent,
        PageMainComponent,
        PortalSeasonAlertComponent,
        PortalSeasonLoadingHexaComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }